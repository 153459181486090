<template>
    <div class="doneOrderList">
        <div class="doneOrderList_searchBox">
            <searchV1
                    class="doneOrderList_searchBox_item"
                    v-model="keyword"
                    @search="search"
                    @clear="search"
            ></searchV1>
        </div>
        <div class="date-ranger-container">
            <date-ranger-v1 @chooseDate="chooseDate"></date-ranger-v1>
        </div>
        <div class="doneOrderList_tip">
            <div>我的佣金：¥ {{ statistics.total_delivery_money }}</div>
            <div>配送单数：{{ statistics.total_sum }}</div>
        </div>
        <div class="doneOrderList_body">
            <scrollview @onRefresh="onRefresh" @onReload="onReload" :height="scrollHeight">
                <template v-if="list.length > 0">
                    <div class="card" v-for="(item, index) in list" :key="index">
                        <div>
                            <cell-v3 :hide-icon="true">
                                <div class="title" slot="title">订单编码：232833</div>
                                <div class="money">+12.34</div>
                            </cell-v3>
                        </div>
                        <div>
                            <cell-v3 :hide-icon="true">
                                <div class="label" slot="title">取货时间：</div>
                                <div class="time">2021-10-17 18:20</div>
                            </cell-v3>
                        </div>
                        <div>
                            <cell-v3 :hide-icon="true">
                                <div class="label" slot="title">送达时间：</div>
                                <div class="time">2021-10-17 18:20</div>
                            </cell-v3>
                        </div>
                    </div>
                </template>
            </scrollview>
        </div>
    </div>
</template>

<script>
  import searchV1 from '../../template/community/search/searchV1'
  import CellV3 from '../../template/community/cell/cellV3'
  import DateRangerV1 from '../../template/community/date/dateRangerV1'

  export default {
    name: 'done-order-list',
    components: {
      DateRangerV1,
      CellV3,
      searchV1,
    },
    data () {
      return {
        keyword: '',
        date: ['', ''],
        statistics: {},
        params: {
          page: 1,
          page_size: 10,
        },
        list: [],
        scrollHeight: 0
      }
    },
    mounted () {
      this.getDoneOrderList()
      this.scrollHeight = window.innerHeight - document.querySelector('.doneOrderList_searchBox').clientHeight - document.querySelector('.date-ranger-container').clientHeight - document.querySelector('.doneOrderList_tip').clientHeight
    },
    methods: {
      onRefresh (done) {
        this.list = []
        this.params.page = 1
        this.getDoneOrderList().finally(() => {
          done()
        })
      },
      /**
       * step 当前加载结束
       * over 没有更多数据了
       */
      onReload (step, over) {
        console.log('上拉加载')
        this.params.page += 1
        this.getDoneOrderList().finally(() => {
          if (this.list.length < this.params.page * this.params.page_size) {
            over()
          } else {
            step()
          }
        })
      },
      gotDetail: function (doId) {
        this.$router.push({path: 'order-info', query: {doId: doId}})
      },
      async getDoneOrderList () {
        try {
          let query = await this.$api.community.courier.getDoneOrderList({
            ...this.params,
            start_time: this.date[0],
            end_time: this.date[1],
            keyword: '',
          })
          this.statistics = query.data.tj
          if (query.data.list.length == 0) {
            return
          }
          this.list = [...this.list, ...query.data.list]
        } catch (e) {
          console.log('err', e)
        }
      },
      search () {
        this.parmas.page = 1
        this.list = []
        this.getDoneOrderList()
      },
      chooseDate (date) {
        this.date = date
        this.list = []
        this.parmas.page = 1
        this.getDoneOrderList()
      },
    },
  }
</script>

<style scoped lang="scss">
    @include b(doneOrderList) {
        width: 100vw;
        height: 100vh;
        background: #f6f6f6;
        display: flex;
        flex-direction: column;
        @include e(searchBox) {
            width: 100%;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            @include e(item) {
                width: 345px;
                height: 32px;
            }
        }
        @include e(tip) {
            flex-shrink: 0;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 12px 15px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #c10000;
            & > div:last-of-type {
                margin-left: 20px;
            }
        }
        @include e(body) {
            flex-grow: 1;
        }
    }

    .date-ranger-container {
        flex-shrink: 0;
        width: 100%;
        height: 44px;
        box-sizing: border-box;
        padding: 0 15px;
    }

    .card {
        background: #fff;
        padding: 15px 15px 9px 15px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        margin-bottom: 15px;

        div {
            margin-bottom: 6px;

            .title {
                font-size: 16px;
                color: #333333;
            }

            .money {
                font-size: 16px;
                color: #c10000;
                font-weight: bold;
            }

            .label,
            .time {
                font-size: 14px;
                color: #333333;
            }
        }
    }
</style>
